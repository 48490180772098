import React from 'react'
import './Hero.css'
import Header from '../Header/Header'
import Hero_image from '../../assets/hero_image.png'
import Calories from '../../assets/calories.png'
import Heart from '../../assets/heart.png'
import Hero_image_back from '../../assets/hero_image_back.png'
import { motion } from 'framer-motion'
import { type } from '@testing-library/user-event/dist/type'


const Hero = () => {
    const transition = {type: 'spring', duration: 3}
  return (
    // Left side
    <div className="hero">
        <div className="blur hero-blur"></div>
        <div className="left-h">
            <Header/>
     {/* The best ad */}
                <div className="the-best-ad">

                    <motion.div
                        initial= {{left: '238px'}}
                        whileInView={{left: '8px'}}
                        transition = {{...transition, type: 'tween'}}
                    ></motion.div>

                    <span>The best fitness club in city</span>
                </div>

                {/* Hero Heading */}
                <div className="hero-text">
                    <div><span className='stroke-text'>Shape </span>
                        <span>Your</span>
                    </div>
                    <div>
                        <span>Ideal Body</span>
                    </div>
                
                     <div>
                    <span>In here we will help you to shape and build your ideal body and live up your life to fulllest  </span>
                    </div>
                </div>

                {/* figures */}
                <div className="figures">
                    <div>
                        <span>+140</span>
                        <span>Export Choaches</span>
                    </div>
                    <div>
                        <span>+978</span>
                        <span>Members Joined</span>
                    </div>
                    <div>
                        <span>+50</span>
                        <span>Fitness Programs</span>
                    </div>
                </div>
                {/* Hero-button */}
                <div className="hero-buttons">
                    <buttons className="btn">Get Started</buttons>
                    <buttons className="btn">Learn More</buttons>

                </div>
            </div>

            {/* right side */}
        <div className="right-h">
            <button className='btn'>Join Now</button>

            <div className="heart-rate">
                
                <img src={Heart} alt="" />
                <span>Heart Rate </span>
                <span>116 bpm</span>
            </div>
             {/* hero images */}
        
             <img src={Hero_image} alt="" className='hero-image' />
            <img src={Hero_image_back} alt="" className='hero-image-back' />

            {/* calories */}
            <div className="calories">
                <img src={Calories} alt="" />
                <div>
                    <span>Calories Burned </span>
                    <span>116 Ckal</span>
                </div>
               
            </div>
        </div>
    </div>
  )
}

export default Hero