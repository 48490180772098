import React from 'react'
import './Plans.css'
import {plansData} from '../../data/plansData'
import whiteTick from '../../assets/whiteTick.png'


const Plans = () => {
  return (
    <div className="plans-container">
      <div className="blur p-blur"></div>
      <div className="blur p-blur1"></div>
        <div className="programs-header">
            <span className='stroke-text'>Ready to start</span>
            <span>Your journey</span>
            <span className='stroke-text'>Now withus</span>
        </div>
        {/* Plans card */}
        <div className="plans">
            {plansData.map((plan)=>(
              <div className="plan">
                {plan.icon}
                <span>{plan.name}</span>
                <span> ${plan.price}</span>

                <div className="features">
                    {plan.features.map((feature)=>(
                        <div className="feature">
                            <img src={whiteTick} alt="" />
                            <span>{feature}</span>
                        </div>
                    ))}
                </div>
                <div><span> See more benefit -- </span></div>
                <button className="btn">Join Now</button> 
              </div>
            ))}
        </div>
        
    </div>
  )
}

export default Plans